// Burger menus
document.addEventListener('DOMContentLoaded', function () {
    // open
    const burger = document.querySelectorAll('.navbar-burger');
    const menu = document.querySelectorAll('.navbar-menu');

    if (burger.length && menu.length) {
        for (var i = 0; i < burger.length; i++) {
            burger[i].addEventListener('click', function () {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    // close
    const close = document.querySelectorAll('.navbar-close');
    const backdrop = document.querySelectorAll('.navbar-backdrop');

    if (close.length) {
        for (var i = 0; i < close.length; i++) {
            close[i].addEventListener('click', function () {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }

    if (backdrop.length) {
        for (var i = 0; i < backdrop.length; i++) {
            backdrop[i].addEventListener('click', function () {
                for (var j = 0; j < menu.length; j++) {
                    menu[j].classList.toggle('hidden');
                }
            });
        }
    }
});



// Dropdown
const trigger = document.querySelector('[data-toggle="dropdown"]');
const dropdown = document.getElementById('dropdown');

trigger.addEventListener('click', () => {
    dropdown.classList.toggle('hidden');
});


const buttons = document.querySelectorAll('[data-selected="false"]');

buttons.forEach((button) => {
    button.addEventListener('click', (event) => {
        const selectedButton = document.querySelector('[data-selected="true"]');

        // Remove the "selected" class from the previously selected button
        if (selectedButton) {
            selectedButton.classList.remove('selected');

            selectedButton.setAttribute('data-selected', 'false');
            console.log(event);
        }

        // Add the "selected" class to the currently selected button
        button.classList.add('selected');
        button.setAttribute('data-selected', 'true');
    });
});


// Get the tab list element
const tabList = document.getElementById("tab-list");

// Add an event listener to the tab list
tabList.addEventListener("click", (event) => {
    // Get the clicked tab element
    const clickedTab = event.target;

    // Get the tab list items
    const tabListItems = tabList.querySelectorAll("li");

    // Loop through the tab list items
    tabListItems.forEach((tabListItem) => {
        // Remove the is-active class from the tab list item
        tabListItem.classList.remove("is-active");

        // Check if the clicked tab is the current tab list item
        if (clickedTab === tabListItem) {
            // Add the is-active class to the clicked tab
            clickedTab.classList.add("is-active");
        }
    });
});



// Get all anchor elements
const anchors = document.querySelectorAll("a");

// Add a click event listener to the anchor elements
anchors.forEach((anchor) => {
    anchor.addEventListener("click", (event) => {
        // Get the anchor's href attribute
        const href = anchor.getAttribute("href");

        // Check if the anchor has an href attribute
        if (href) {
            // Prevent the default click behavior
            event.preventDefault();

            // Get the target element
            const target = document.getElementById(href.replace("#", ""));

            // Check if the target element exists
            if (target) {
                // Smoothly scroll the page to the target element
                target.scrollIntoView({ behavior: "smooth" });
            }
        }
    });
});




Dropzone.options.myDropzone = {
    autoProcessQueue: false,
    paramName: 'file',
    maxFilesize: 20,
    clickable: "dropZone"
};